<template>
	<div v-loading.fullscreen.lock="fullscreenLoading" class="pdf-images">
		<img src="../../../../assets/csiiPdf/csii1.png" @click="showImg(1)">
		<img src="../../../../assets/csiiPdf/csii2.png" @click="showImg(2)">
	</div>
</template>

<script lang="js">
import { ImagePreview } from "vant";
import 'vant/lib/index.css';

export default {
	data() {
		return {
			fullscreenLoading: false,
			imgList: [
				require('../../../../assets/csiiPdf/csii1.png'),
				require('../../../../assets/csiiPdf/csii2.png')
			]
		};
	},
	mounted() {
		this.openFullScreen1();
	},
	methods: {
		openFullScreen1() {
			this.fullscreenLoading = true;
			setTimeout(() => {
				this.fullscreenLoading = false;
			}, 1000);
		},
		showImg(index) {
			ImagePreview({
				images: this.imgList,
				showIndex: true,
				loop: true,
				startPosition: index - 1
			});
		}
	}
};
</script>

<style>
	.pdf-images{
		width: 100%;
		float: left;
	}
	.pdf-images img{
		width: 100%;
		float: left;
	}
</style>
